import { useState, useEffect } from "react";
import { Search, X, CheckCircle } from "lucide-react";

const EnhancedQuestionSelector = ({
  questions,
  selectedQuestions,
  onChange,
}: {
  questions: any[];
  selectedQuestions: any[];
  onChange: (questions: any[]) => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState(questions);

  useEffect(() => {
    const filtered = questions.filter((question) =>
      question.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(filtered);
  }, [searchTerm, questions]);

  const toggleQuestion = (question: any) => {
    const updatedSelection = selectedQuestions.some(
      (q) => q._id === question._id
    )
      ? selectedQuestions.filter((q) => q._id !== question._id)
      : [...selectedQuestions, question];
    onChange(updatedSelection);
  };

  return (
    <div className="space-y-6 bg-white rounded-lg"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()}}
    >
      <div className="relative mt-4">
        <input
          type="text"
          placeholder="Search questions..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>

      {selectedQuestions.length > 0 && (
        <div className="bg-blue-50 p-4 rounded-lg">
          <h3 className="text-sm font-semibold text-blue-800 mb-2">Selected Questions:</h3>
          <div className="flex flex-wrap gap-2">
            {selectedQuestions.map((question) => (
              <span
                key={question._id}
                className="inline-flex items-center bg-white px-3 py-1 rounded-full text-sm font-medium text-blue-800 "
              >
                {question.title}
                <button
                  type="button"
                  className="ml-2 inline-flex items-center justify-center h-5 w-5 rounded-full bg-blue-200 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-200"
                  onClick={() => toggleQuestion(question)}
                >
                  <X className="h-3 w-3 text-blue-800" />
                </button>
              </span>
            ))}
          </div>
        </div>
      )}

      <div className="bg-gray-50 rounded-lg border border-gray-200 overflow-hidden">
        <div className="max-h-[400px] overflow-y-auto">
          {filteredQuestions.length === 0 ? (
            <p className="text-center py-4 text-gray-500">No questions found</p>
          ) : (
            filteredQuestions.map((question) => {
              const isSelected = selectedQuestions.some((q) => q._id === question._id);
              return (
                <button
                  key={question._id}
                  className={`w-full text-left p-4 hover:bg-gray-100 flex items-center justify-between transition duration-200 ${
                    isSelected ? "bg-blue-50" : ""
                  }`}
                  onClick={() => toggleQuestion(question)}
                >
                  <span className="flex-grow pr-4">{question.title}</span>
                  {isSelected && (
                    <CheckCircle className="h-5 w-5 text-blue-600 flex-shrink-0" />
                  )}
                </button>
              );
            })
          )}
        </div>
      </div>

      <div className="flex justify-between items-center text-sm text-gray-600">
        <span>
          Selected: {selectedQuestions.length} / {questions.length}
        </span>
        {selectedQuestions.length > 0 && (
          <button
            onClick={() => onChange([])}
            className="text-red-600 hover:text-red-800 font-medium transition duration-200"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  );
};

export default EnhancedQuestionSelector;